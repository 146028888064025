@import "./theme/_variables";

@import "~bootstrap/scss/bootstrap.scss";
@import "~react-loader-spinner/dist/loader/css/react-spinner-loader.css";

@import "./containers//layout";
@import "./components/loader";

@import "./theme/_override";

