.text-blue {color: $blue;}
.text-yellow {color: $green;}

.bg-blue {background-color: $blue;}
.bg-yellow {background-color: $green;}
.bg-gray {background-color: $gray;}

.btn-blue {background-color: $blue; color:$green;}
.btn-yellow {background-color: $green; color:$blue;}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:visited
{background-color: $green; color:$blue;}

.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:visited
{background-color: $blue; color:$green;}

label{margin-bottom: 0;}