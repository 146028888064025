header {
    height: 100px;
    text-align: center;
    img {
        margin-top: 10px;
        width: 400px
    }
}

main{
    height: calc(100vh - 160px); 
    background-color:$azure;
    color: $blue;
}

footer {
    height: 60px; 
    border-top: 1px solid $blue; 
    background-color: $green;
    text-align: center;
    color: #fff;
    padding: 20px 0;;
    font-size: 0.8rem;
}

@media (max-width: 767px) {
    header {
        img {
            width: 300px
        }
    }
}